import React from 'react';
import classNames from 'classnames';
import SectionHeader, {SectionHeaderTag} from '../partials/SectionHeader';
import Image from '../../elements/Image';
import {SectionTilesTypesProps} from '../../../utils/SectionProps';

interface ClientsProps extends SectionTilesTypesProps {
  className?: string;
  title: string;
}

const defaultProps: Partial<ClientsProps> = {
  topOuterDivider: false,
  bottomOuterDivider: false,
  topDivider: false,
  bottomDivider: false,
  hasBgColor: false,
  invertColor: false,
  pushLeft: false,
};

const Clients: React.FC<ClientsProps> = ({
                                           className,
                                           topOuterDivider,
                                           bottomOuterDivider,
                                           topDivider,
                                           bottomDivider,
                                           hasBgColor,
                                           invertColor,
                                           pushLeft,
                                           title,
                                           ...props
                                         }) => {
  const outerClasses = classNames(
    'clients section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );
  return (
    <section id="clients-section" {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader tag={SectionHeaderTag.H2} title={title} className="center-content"/>
          <div className="d-flex justify-content-center align-items-center flex-wrap m-n24 px-md-32 reveal-from-bottom">
            <Image
              src={require('../../../assets/images/clients/logo-telia.svg')}
              alt="Telia"
              className="m-24"
              width={130}
            />
            <Image
              src={require('../../../assets/images/clients/logo-ignitis-grupe.svg')}
              alt="Ignitis grupė"
              className="m-24"
              width={120}
            />
            <Image
              src={require('../../../assets/images/clients/logo-acme-grupe.png')}
              alt="ACME grupė"
              className="m-24"
              width={156}
            />
            <Image
              src={require('../../../assets/images/clients/logo-olvi.svg')}
              alt="OLVI"
              className="m-24"
              width={64}
            />
            <Image
              src={require('../../../assets/images/clients/logo-sba.png')}
              alt="SBA"
              className="m-24"
              width={108}
            />
            <Image
              src={require('../../../assets/images/clients/logo-franmax.png')}
              alt="FRANMAX"
              className="m-24"
              width={154}
            />
            <Image
              src={require('../../../assets/images/clients/logo-senukai.svg')}
              alt="Senukai"
              className="m-24"
              width={120}
            />
            <Image
              src={require('../../../assets/images/clients/logo-sb-lizingas.png')}
              alt="SB lizingas"
              className="m-24"
              width={128}
            />
            <Image
              src={require('../../../assets/images/clients/logo-5ci.svg')}
              alt="Penki Kontitentai"
              className="m-24"
              width={64}
            />
            <Image
              src={require('../../../assets/images/clients/logo-narbutas.svg')}
              alt="Narbutas"
              className="m-24"
              width={220}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

Clients.defaultProps = defaultProps;

export default Clients;
