import React from 'react';
import classNames from 'classnames';
import { SectionSplit, SectionSplitTypesProps } from '../../../utils/SectionProps';
import SectionHeader from '../partials/SectionHeader';
import Image from '../../elements/Image';
import { Partner } from '../../../types/partner';

interface PartnersProps extends SectionSplitTypesProps {
  className?: string;
  partners: Partner[];
  title: string;
  description?: string;
}

const defaultProps: Partial<PartnersProps> = {
  ...SectionSplit.defaults,
  partners: [],
};

const Partners: React.FC<PartnersProps> = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  partners,
  title,
  description,
  ...props
}) => {
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top',
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container overflow-hidden">
        <div className={innerClasses}>
          <SectionHeader title={title} className="center-content">
            {description && <p className="mb-0">{description}</p>}
          </SectionHeader>
          <div className={splitClasses}>
            {partners.map((partner, index) => (
              <div className="split-item" key={index}>
                <div
                  className={classNames(
                    'split-item-content center-content-mobile',
                    index % 2 ? 'reveal-from-left' : 'reveal-from-right',
                  )}
                  data-reveal-container=".split-item"
                >
                  <h3 className="mt-0 mb-12">
                    <a href={partner.url} target="_blank" rel="noopener noreferrer">
                      {partner.title}
                    </a>
                  </h3>
                  <p className="m-0">{partner.description}</p>
                </div>
                <div
                  className={classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill',
                  )}
                  data-reveal-container=".split-item"
                >
                  <a href={partner.url} target="_blank" rel="noopener noreferrer">
                    <Image src={partner.image.publicURL} alt={partner.title} width={528} height={396} />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

Partners.defaultProps = defaultProps;

export default Partners;
